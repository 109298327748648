import axios from 'axios'
import queryString from 'query-string'
import Config from '../configs'

// Set up default config for http requests here
console.log(Config.apiConfig.endPoint)
const axiosClient = axios.create({
  baseURL: Config.apiConfig.endPoint,
  headers: {
    'content-type': 'application/json'
  },
  paramsSerializer: (params) => queryString.stringify(params)
})

axiosClient.interceptors.request.use(async (config) => {
  // const authItem = sessionStorage.getItem(Config.storageKey.auth)
  // if (authItem) {
  //   const auth = { ...JSON.parse(authItem) }
  //   if (auth.token) {
  //     config.headers.Authorization = `Bearer ${auth.token}`
  //   }
  // }
  const data = localStorage.getItem('userData') as string
  const cleanedData = data?.replace(/^"(.*)"$/, '$1')
  if (cleanedData) {
    config.headers.Authorization = `Bearer ${cleanedData}`
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }

    return response
  },
  (error) => {
    // Handle errors
    throw error
  }
)

export default axiosClient
