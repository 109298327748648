import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import factoryApi from '~/api/factoryApi'
import serviceRequest from '~/app/serviceRequest'
import {FactoryType} from '../factory-detail/FactoryDetail.type'
import {PayloadFactory} from '~/api/types/factory'

export const getFactories = createAsyncThunk(
  'factory/getFactories',
  async (params: {pageNumber: number; pageSize: number}, thunkAPI) => {
    const {pageNumber, pageSize} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: factoryApi.getAllFactories,
      payload: {pageNumber, pageSize},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const createFactory = createAsyncThunk('factory/createFactory', async (data: any | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: factoryApi.postFactory,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const updateFactory = createAsyncThunk(
  'factory/updateFactory',
  async (data: PayloadFactory | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: factoryApi.updateFactory,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const deleteFactory = createAsyncThunk('factory/deleteFactory', async (data: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: factoryApi.deleteFactory,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export type State = {
  loading: string
  data: FactoryType[]
  error: string
  paginationData: {page: number; pageSize: number; totalPage: number}[]
}

const initialState: State = {
  loading: 'idle',
  data: [] as FactoryType[],
  error: '',
  paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
}

const factory = createSlice({
  name: 'factory',
  initialState: initialState,
  reducers: {
    handleChangePage: (state, action) => {
      const {activePage, page} = action.payload
      state.paginationData[activePage].page = page
    },
    handleChangePageSize: (state, action) => {
      const {activePage, pageSize} = action.payload
      state.paginationData[activePage].pageSize = pageSize
    },
    handleChangeTotalPage: (state, action) => {
      const {activePage, totalPage = 1} = action.payload
      state.paginationData[activePage].totalPage = totalPage
    },
  },
  extraReducers: builder => {
    builder
      //getFactories
      .addCase(getFactories.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(getFactories.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action?.payload
        if (payload?.isSuccess) {
          state.data = payload?.data?.items
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(getFactories.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //createFactory
      .addCase(createFactory.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(createFactory.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload
        if (payload?.isSuccess) {
          state.data.unshift(payload.data)
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(createFactory.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //updateFactory
      .addCase(updateFactory.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(updateFactory.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload
        if (payload?.isSuccess) {
          const updateData = state.data.map(obj => (obj.id === action.payload.data.id ? action.payload.data : obj))
          state.data = updateData
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(updateFactory.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //deleteFactory
      .addCase(deleteFactory.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(deleteFactory.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload

        if (payload?.isSuccess) {
          const update = current(state.data).filter(obj => obj.id !== payload.data.id)
          state.data = update
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(deleteFactory.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })
  },
})

const {reducer} = factory
export default reducer
