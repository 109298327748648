import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import finalReportApi from '~/api/finalReportApi'
import serviceRequest from '~/app/serviceRequest'

export const getAllSupplier = createAsyncThunk(
    'finalreport/download', async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: finalReportApi.downloadReport,
            payload: data,
            options: {
              skipLoader: false
            }
          })
    }
)

export const postFile = createAsyncThunk (
    'finalreport/uploadreport', async (data: attachment, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: finalReportApi.postReport,
            payload: data,
            options: {
                skipLoader: false
            }
        })
    }
)

export const postFileAudit = createAsyncThunk (
    'finalreport/uploadreport', async (data: attachment, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: finalReportApi.postReportAudit,
            payload: data,
            options: {
                skipLoader: false
            }
        })
    }
)

export const deleteFile = createAsyncThunk (
    'finalreport/deletefile', async (data: string, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: finalReportApi.deleteFile,
            payload: data,
            options: {
                skipLoader: false
            }
        })
    }
)

const finalreport = createSlice({
    name: 'supplier',
    initialState: {},
    reducers: {
      // handleChangePage: (state, action) => {
      //   const { activePage, page } = action.payload
      //   state.paginationData[activePage].page = page
      // },
      // handleChangePageSize: (state, action) => {
      //   const { activePage, pageSize } = action.payload
      //   state.paginationData[activePage].pageSize = pageSize
      // },
      // handleChangeTotalPage: (state, action) => {
      //   const { activePage, totalPage = 1 } = action.payload
      //   state.paginationData[activePage].totalPage = totalPage
      // }
    },
    extraReducers: () => {}
  })
  
  const { reducer } = finalreport
  export default reducer