import { payloadLogin } from '~/pages/login/Login.type'
import axiosClient from './axiosClient'

const authApi = {
  loginAccount: (data: payloadLogin) => {    
    const url = '/identityusers/login'
    return axiosClient.post(url, data)
  },

  fetchUserData: () => {
    const url = '/identityusers/getuserbyid'
    return axiosClient.get(url)
  }
}

export default authApi
