// Core
import { FC, startTransition } from 'react'

// MUI
import { Button } from '@mui/material'

// Icons
import { History as HistoryIcon } from '@mui/icons-material'

// App
import Err500Image from '~/assets/images/error/err500.png'
import { ErrorProps } from './Error.type'
import './Error.css'

// Component
const Error: FC<ErrorProps> = ({ resetErrorBoundary }) => {
  // Handle when navigate away (Don't remove!)
  //   useEffect(() => {
  //     if (urlPathname !== errorLocation.current) {
  //       resetErrorBoundary()
  //     }
  //   }, [urlPathname, resetErrorBoundary])

  // Methods
  // Handle click
  const handleClick = () => {
    startTransition(() => {
      resetErrorBoundary()
    })
  }

  // Template
  return (
    <div className='w-screen h-screen flex justify-center items-center py-10'>
      <div className='app-container'>
        <div className='flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20'>
          <img src={Err500Image} alt='' className='w-1/2 lg:w-1/3' />
          <div className='flex flex-col items-center gap-4'>
            <h2 className='text-app-blue font-bold text-2xl'>Opps! Có lỗi xảy ra!</h2>
            <Button onClick={handleClick} variant='contained' startIcon={<HistoryIcon />} className='!normal-case'>
              Quay lại Trang chủ
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
