import axiosClient from './axiosClient'

const finalReportApi = {
  downloadReport: (params: any) => {
    const url = `/finalreport/download/${params.id}`
    return axiosClient.get(url)
  },
  postReport: (params: attachment) => {
    const url = `/finalreport/uploadreport/${params.id}`
    return axiosClient.post(url, params.file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  },
  postReportAudit: (params: attachment) => {
    const url = `/finalreport/uploadreportaudit/${params.id}`
    return axiosClient.post(url, params.file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  },
  deleteFile: (id: string) => {
    const url = `/finalreport/delete/${id}`
    return axiosClient.delete(url)
  }
}

export default finalReportApi
