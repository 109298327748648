import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import inspectionApi from '../../api/inspectionApi'
import serviceRequest from '~/app/serviceRequest'

export const getInspectionsType = createAsyncThunk('inspection/gettype', async (data: undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: inspectionApi.getInspectionType,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const createInspectionType = createAsyncThunk(
  'inspection/createInspectionType',
  async (data: string, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: inspectionApi.postInspectionType,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const deleteInspectionType = createAsyncThunk('inspection/deletetype', async (data: string, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: inspectionApi.deleteInspectionType,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const getInspectionStatus = createAsyncThunk('inspection/getstatus', async (data, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: inspectionApi.getInspectionStatus,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

const inspection = createSlice({
  name: 'inspection',
  initialState: {},
  reducers: {
    // handleChangePage: (state, action) => {
    //   const { activePage, page } = action.payload
    //   state.paginationData[activePage].page = page
    // },
    // handleChangePageSize: (state, action) => {
    //   const { activePage, pageSize } = action.payload
    //   state.paginationData[activePage].pageSize = pageSize
    // },
    // handleChangeTotalPage: (state, action) => {
    //   const { activePage, totalPage = 1 } = action.payload
    //   state.paginationData[activePage].totalPage = totalPage
    // }
  },
  extraReducers: () => {},
})

const {reducer} = inspection
export default reducer
