import {GetThunkAPI, createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import supplierApi from '~/api/supplierApi'
import serviceRequest from '~/app/serviceRequest'
import {AsyncThunkConfig} from 'node_modules/@reduxjs/toolkit/dist/createAsyncThunk'
import {SupplierType} from '../supplier-detail/SupplierDetail.type'
import {PayloadSupplier} from '~/api/types/supplier'

export const getSuppliers = createAsyncThunk(
  'supplier/getSuppliers',
  async (params: {pageNumber: number; pageSize: number}, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    const {pageNumber, pageSize} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: supplierApi.getAllSupplier,
      payload: {pageNumber, pageSize},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const createSupplier = createAsyncThunk(
  'supplier/createSupplier',
  async (data: any | undefined, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: supplierApi.postSupplier,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const updateSupplier = createAsyncThunk(
  'supplier/updateSupplier',
  async (data: PayloadSupplier | undefined, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: supplierApi.updateSupplier,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const deleteSupplier = createAsyncThunk('supplier/deleteSupplier', async (id: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: supplierApi.deleteSupplier,
    payload: id,
    options: {
      skipLoader: false,
    },
  })
})

const initialState = {
  loading: 'idle',
  data: [] as SupplierType[],
  error: '',
  paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
}

const supplier = createSlice({
  name: 'supplier',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //getSuppliers
      .addCase(getSuppliers.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action?.payload
        if (payload?.isSuccess) {
          state.data = payload?.data?.items
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(getSuppliers.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //createFactory
      .addCase(createSupplier.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(createSupplier.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload

        if (payload?.isSuccess) {
          state.data.unshift(payload.data)
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(createSupplier.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //updateSupplier
      .addCase(updateSupplier.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(updateSupplier.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload
        if (payload?.isSuccess) {
          const updateData = state.data.map(obj => (obj.id === action.payload.data.id ? action.payload.data : obj))
          state.data = updateData
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(updateSupplier.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })

      //deleteSupplier
      .addCase(deleteSupplier.pending, state => {
        state.loading = 'loading'
        state.error = initialState.error
      })
      .addCase(deleteSupplier.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        const payload = action.payload
        if (payload?.isSuccess) {
          const update = current(state.data).filter(obj => obj.id !== action.payload.data.id)
          state.data = update
        } else if (!payload?.IsSuccess) {
          state.error = payload?.Data?.ErrorMessage
        }
      })
      .addCase(deleteSupplier.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message as string
      })
  },
})

const {reducer} = supplier
export default reducer
