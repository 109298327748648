import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import attachmentApi from '~/api/attachmentApi'
import serviceRequest from '~/app/serviceRequest'

export const uploadAttachment = createAsyncThunk('attachment/uploadimage', async (data : attachment, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: attachmentApi.uploadAttachment,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const uploadAttachmentAudit = createAsyncThunk('attachment/uploadimage', async (data : attachment, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: attachmentApi.uploadAttachmentAudit,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const downloadAttachment = createAsyncThunk('attachment/download', async (data, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: attachmentApi.downloadAttachment,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
})

export const deleteAttachment = createAsyncThunk('attachment/delete', async (data: string | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: attachmentApi.deleteAttachment,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
})

const attachment = createSlice({
  name: 'attachment',
  initialState: {},
  reducers: {
    // handleChangePage: (state, action) => {
    //   const { activePage, page } = action.payload
    //   state.paginationData[activePage].page = page
    // },
    // handleChangePageSize: (state, action) => {
    //   const { activePage, pageSize } = action.payload
    //   state.paginationData[activePage].pageSize = pageSize
    // },
    // handleChangeTotalPage: (state, action) => {
    //   const { activePage, totalPage = 1 } = action.payload
    //   state.paginationData[activePage].totalPage = totalPage
    // }
  },
  extraReducers: () => {},
})

const {reducer} = attachment
export default reducer
