import axiosClient from './axiosClient'

const inspectionApi = {
  getInspectionType: () => {
    const url = '/inspectioninfo/gettype'
    return axiosClient.get(url)
  },
  postInspectionType: (data: string) => {
    const url = '/inspectioninfo/createinspectiontype?nameInspectionType=' + encodeURIComponent(data)
    return axiosClient.post(url)
  },
  deleteInspectionType: (data: string) => {
    const url = `/inspectioninfo/deleteinspectiontype/${data}`
    return axiosClient.delete(url)
  },
  getInspectionStatus: () => {
    const url = '/inspectioninfo/getstatus'
    return axiosClient.get(url)
  },
}

export default inspectionApi
