import axiosClient from './axiosClient'
import {PayloadSupplier} from './types/supplier'

const supplierApi = {
  getAllSupplier: (params: any) => {
    const url = '/supplier/getall'
    return axiosClient.get(url, {params})
  },
  postSupplier: (data: PayloadSupplier) => {
    const postData = {
      name: data.name,
      country: data.country,
      contactPersons: [data.contactPerson],
      email: data.email,
      phoneNumbers: [data.phoneNumber],
      address: data.address,
      remark: data.remark,
    }
    const url = '/supplier/createsupplier'
    return axiosClient.post(url, postData)
  },
  updateSupplier: (data: PayloadSupplier) => {
    const updateData = {
      name: data.name,
      country: data.country,
      contactPersons: [data.contactPerson],
      email: data.email,
      phoneNumbers: [data.phoneNumber],
      address: data.address,
      remark: data.remark,
    }
    const id = data.id
    const url = `/supplier/updateclient/${id}`
    return axiosClient.put(url, updateData)
  },
  deleteSupplier: (id: string) => {
    const url = `/supplier/deletesupplier?Id=${id}`
    return axiosClient.delete(url)
  },
}

export default supplierApi
