import axiosClient from './axiosClient'

const paymentApi = {
  getPaymentMethod: () => {
    const url = '/paymentinfo/getmethod'
    return axiosClient.get(url)
  },
  deletePaymentMethod: (id: string) => {
    const url = `/paymentinfo/deletepaymentmethod/${id}`
    return axiosClient.delete(url)
  },
  getPaymentStatus: () => {
    const url = '/paymentinfo/getstatus'
    return axiosClient.get(url)
  },
  postPaymentMethod: (data: string) => {
    const url = 'paymentinfo/createpaymentmethod?namePaymentMethod=' + encodeURIComponent(data)
    return axiosClient.post(url)
  },
}

export default paymentApi
