import { Components, Theme, createTheme } from '@mui/material'
import { AppThemeMode } from '~/stores/useThemeModeStore'
import { deepOrange } from '@mui/material/colors'

const ThemeOptionsComponent: Components<Omit<Theme, 'components'>> = {
  // Link
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#0f62fe',
        fontSize: 14,
        leading: 1.5
      }
    }
  },
  // InputBase
  MuiInputBase: {
    styleOverrides: {
      input: {
        textOverflow: 'ellipsis'
      }
    }
  },
  // OutlinedInput
  MuiOutlinedInput: {
    styleOverrides: {
      multiline: {
        paddingLeft: 0,
        paddingRight: 0
      },
      input: {
        paddingLeft: '16px',
        paddingRight: '16px'
      },
      inputAdornedStart: {
        paddingLeft: '0px',
        paddingRight: '16px'
      },
      inputAdornedEnd: {
        paddingLeft: '16px',
        paddingRight: '0px'
      },
      notchedOutline: {
        borderColor: '#79747e'
      }
    }
  },
  // InputAdornment
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: '#525252'
      }
    }
  },
  // FormLabel
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: '#79747e'
      }
    }
  }
}

const muiThemes: Record<AppThemeMode, Theme> = {
  // Light
  light: createTheme({
    palette: {
      mode: 'light',
      text: {
        primary: '#515151'
      },
      primary: {
        main: '#004392'
      },
      secondary: {
        main: '#dd542b'
      },
      info: {
        main: '#515151'
      },
      error: {
        main: '#da1e28'
      }
    },
    components: ThemeOptionsComponent
  }),

  // Dark
  dark: createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: deepOrange[900]
      }
    },

    components: ThemeOptionsComponent
  })
}

export default muiThemes
