import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userApi from '../../api/userApi'
import serviceRequest from '~/app/serviceRequest'

export const getAllUser = createAsyncThunk(
    'inspection/gettype', async (data, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: userApi.getAll,
            payload: data,
            options: {
              skipLoader: false
            }
          })
    }
)

const user = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
      // handleChangePage: (state, action) => {
      //   const { activePage, page } = action.payload
      //   state.paginationData[activePage].page = page
      // },
      // handleChangePageSize: (state, action) => {
      //   const { activePage, pageSize } = action.payload
      //   state.paginationData[activePage].pageSize = pageSize
      // },
      // handleChangeTotalPage: (state, action) => {
      //   const { activePage, totalPage = 1 } = action.payload
      //   state.paginationData[activePage].totalPage = totalPage
      // }
    },
    extraReducers: () => {}
  })
  
  const { reducer } = user
  export default reducer