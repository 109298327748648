import {finishLoading, startLoading} from './loaderSlice'

type optionsType = {
  skipLoader: boolean
}

type serviceRequestType = {
  options: optionsType
  dispatch: any
  serviceMethod: any
  payload: any
}

const serviceRequest = async (serviceRequest: serviceRequestType) => {
  const {options, dispatch, serviceMethod, payload} = serviceRequest
  try {
    if (!options.skipLoader) {
      dispatch(startLoading())
    }
    const serviceRequestResponse = await serviceMethod(payload)
    return serviceRequestResponse
  } catch (e) {
    if (!e.response) {
      throw e
    }
    return e.response.data
  } finally {
    if (!options.skipLoader) {
      dispatch(finishLoading())
    }
  }
}

export default serviceRequest
