import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import paymentApi from '~/api/paymentApi'
import serviceRequest from '~/app/serviceRequest'

export const getPaymentMethod = createAsyncThunk('inspection/gettype', async (data, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: paymentApi.getPaymentMethod,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const deletePaymentMethod = createAsyncThunk('inspection/deletemethod', async (data: string, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: paymentApi.deletePaymentMethod,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const getPaymentStatus = createAsyncThunk('inspection/getstatus', async (data, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: paymentApi.getPaymentStatus,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const createPaymentmethod = createAsyncThunk(
  'inspection/createPaymentmethod',
  async (data: string, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: paymentApi.postPaymentMethod,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

const payment = createSlice({
  name: 'payment',
  initialState: {},
  reducers: {
    // handleChangePage: (state, action) => {
    //   const { activePage, page } = action.payload
    //   state.paginationData[activePage].page = page
    // },
    // handleChangePageSize: (state, action) => {
    //   const { activePage, pageSize } = action.payload
    //   state.paginationData[activePage].pageSize = pageSize
    // },
    // handleChangeTotalPage: (state, action) => {
    //   const { activePage, totalPage = 1 } = action.payload
    //   state.paginationData[activePage].totalPage = totalPage
    // }
  },
  extraReducers: () => {},
})

const {reducer} = payment
export default reducer
