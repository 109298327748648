import dayjs from 'dayjs' 
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc) 

export const formatDate = (rawDate: Date, formatType: string) => {
  const formatedDate = new Date(rawDate)

  return formatType !== '' ? dayjs.utc(formatedDate).format(formatType).toString() : dayjs.utc(formatedDate).toString()
}

export const dateFormatTypes = {
  _yyyymmDD: 'YYYY-MM-DD',
  _MMMMDYYYYHMmA: 'MMMM D, YYYY h:mm A',
}
