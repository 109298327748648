import axiosClient from './axiosClient'

const attachmentApi = {
  uploadAttachment: (data: attachment) => {
    const url = `/attachment/${data.id}/uploadimage`
    return axiosClient.post(url, data.file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  },

  uploadAttachmentAudit: (data: attachment) => {
    const url = `/attachment/${data.id}/uploadimageaudit`
    return axiosClient.post(url, data.file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  },

  deleteAttachment: (payload: string) => {
    const url = `/attachment/delete/${payload}`
    return axiosClient.delete(url);
  },

  downloadAttachment: (payload: string) => {
    const url = `/attachment/download/${payload}`
    return axiosClient.get(url);
  }
}

export default attachmentApi
