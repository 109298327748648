// Core
import {FC, ReactNode, lazy} from 'react'

// Layouts
const Layout = lazy(() => import('~/layouts'))
const LayoutLogin = lazy(() => import('~/layouts/layout-login'))

// Pages

// ORDER SECTION
const CreateOrder = lazy(() => import('~/pages/create-order'))
const OrderList = lazy(() => import('~/pages/order-list'))
const OrderDetail = lazy(() => import('~/pages/order-detail'))
const EditOrder = lazy(() => import('~/pages/edit-order'))

// CLIENT SECTION
const ClientList = lazy(() => import('~/pages/client-list'))
const CreateClient = lazy(() => import('~/pages/create-client'))
const ClientDetail = lazy(() => import('~/pages/client-detail'))

// AUTH SECTION
const Login = lazy(() => import('~/pages/login'))
const ForgotPass = lazy(() => import('~/pages/forgot-pass'))
const ForgotPassCode = lazy(() => import('~/pages/forgot-pass/forgot-pass-code'))
const NewPass = lazy(() => import('~/pages/forgot-pass/new-pass'))

// USER SECTION
const UserList = lazy(() => import('~/pages/user-list'))
const UserDetail = lazy(() => import('~/pages/user-detail'))
const CreateUser = lazy(() => import('~/pages/create-user'))
const UpdateUser = lazy(() => import('~/pages/update-user'))

// PROFILE SECTION
const Profile = lazy(() => import('~/pages/profile'))
const EditProfile = lazy(() => import('~/pages/edit-profile'))
const FactoryList = lazy(() => import('~/pages/factory-list'))
const CreateFactory = lazy(() => import('~/pages/create-factory'))
const FactoryDetail = lazy(() => import('~/pages/factory-detail'))
const EditFactory = lazy(() => import('~/pages/edit-factory'))

// SUPPLIER SECTION
const SupplierList = lazy(() => import('~/pages/supplier-list'))
const CreateSupplier = lazy(() => import('~/pages/create-supplier'))
const SupplierDetail = lazy(() => import('~/pages/supplier-detail'))
const EditSupplier = lazy(() => import('~/pages/edit-supplier'))

// FACTORY AUDIT SECTION
const AuditList = lazy(() => import('~/pages/audit-list'))
const CreateAudit = lazy(() => import('~/pages/create-audit'))
const AuditDetail = lazy(() => import('~/pages/audit-detail'))
const AuditEdit = lazy(() => import('~/pages/audit-edit'))

// DASHBOARD SECTION
const Dashboard = lazy(() => import('~/pages/dashboard'))
const Overview = lazy(() => import('~/pages/overview'))

const routes: Array<{
  path: string
  layout: FC<{
    children: ReactNode
  }>
  component: FC
  public: boolean
}> = [
  {
    path: '/',
    layout: LayoutLogin,
    component: Login,
    public: true,
  },
  {
    path: '/forgot-pass',
    layout: LayoutLogin,
    component: ForgotPass,
    public: true,
  },
  {
    path: '/forgot-pass/code',
    layout: LayoutLogin,
    component: ForgotPassCode,
    public: true,
  },
  {
    path: '/forgot-pass/newpass',
    layout: LayoutLogin,
    component: NewPass,
    public: true,
  },
  {
    path: '/order',
    layout: Layout,
    component: OrderList,
    public: false,
  },
  {
    path: '/order/:id',
    layout: Layout,
    component: OrderDetail,
    public: false,
  },
  {
    path: '/edit-order',
    layout: Layout,
    component: EditOrder,
    public: false,
  },
  {
    path: '/create-order',
    layout: Layout,
    component: CreateOrder,
    public: false,
  },
  {
    path: '/client',
    layout: Layout,
    component: ClientList,
    public: false,
  },
  {
    path: '/client/create',
    layout: Layout,
    component: CreateClient,
    public: false,
  },
  {
    path: '/client/:id',
    layout: Layout,
    component: ClientDetail,
    public: false,
  },
  {
    path: '/user',
    layout: Layout,
    component: UserList,
    public: false,
  },
  {
    path: '/user/:id',
    layout: Layout,
    component: UserDetail,
    public: false,
  },
  {
    path: '/user/create',
    layout: Layout,
    component: CreateUser,
    public: false,
  },
  {
    path: '/user/edit',
    layout: Layout,
    component: UpdateUser,
    public: false,
  },
  {
    path: '/:id',
    layout: Layout,
    component: Profile,
    public: false,
  },
  {
    path: '/:id/edit',
    layout: Layout,
    component: EditProfile,
    public: false,
  },
  {
    path: '/factory',
    layout: Layout,
    component: FactoryList,
    public: false,
  },
  {
    path: '/factory/create',
    layout: Layout,
    component: CreateFactory,
    public: false,
  },
  {
    path: '/factory/:id',
    layout: Layout,
    component: FactoryDetail,
    public: false,
  },
  {
    path: '/factory/edit',
    layout: Layout,
    component: EditFactory,
    public: false,
  },
  {
    path: '/supplier',
    layout: Layout,
    component: SupplierList,
    public: false,
  },
  {
    path: '/supplier/create',
    layout: Layout,
    component: CreateSupplier,
    public: false,
  },
  {
    path: '/supplier/:id',
    layout: Layout,
    component: SupplierDetail,
    public: false,
  },
  {
    path: '/supplier/edit',
    layout: Layout,
    component: EditSupplier,
    public: false,
  },
  {
    path: '/audit',
    layout: Layout,
    component: AuditList,
    public: false,
  },
  {
    path: '/audit/create',
    layout: Layout,
    component: CreateAudit,
    public: false,
  },
  {
    path: '/audit/:id',
    layout: Layout,
    component: AuditDetail,
    public: false,
  },
  {
    path: '/audit-edit',
    layout: Layout,
    component: AuditEdit,
    public: false,
  },
  {
    path: '/revenue',
    layout: Layout,
    component: Dashboard,
    public: false,
  },
  {
    path: '/overview',
    layout: Layout,
    component: Overview,
    public: false,
  },
]

export default routes
