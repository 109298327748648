import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authApi from '~/api/authenticateApi'
import serviceRequest from '~/app/serviceRequest'
import { payloadLogin } from './Login.type'

export const login = createAsyncThunk('user/login', async (data: payloadLogin | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: authApi.loginAccount,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (data: unknown | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: authApi.fetchUserData,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

const authenticate = createSlice({
  name: 'authenticate',
  initialState: {},
  reducers: {},
})

const { reducer, actions } = authenticate
export default reducer
