// Core
import { FC } from 'react'

// MUI
import { Box, LinearProgress } from '@mui/material'

// App
import { LoadingProps } from './Loading.type'
import './Loading.css'

// Component
const Loading: FC<LoadingProps> = () => {
  // Template
  return (
    <Box className='fixed top-0 left-0 right-0' sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
      <LinearProgress sx={{ color: '#24356b' }} />
    </Box>
  )
}

export default Loading
