// Core
import ReactDOM from 'react-dom/client'

// App
import App from './App.tsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <App />
)
