import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import orderApi from '~/api/orderApi'
import serviceRequest from '~/app/serviceRequest'

const initialState = () => {
  return {
    paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
  }
}

export const getOrders = createAsyncThunk('order/getOrders', async (data: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.getOrders,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const getOrdersByUserId = createAsyncThunk(
  'order/getOrdersByUserId',
  async (data: unknown | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: orderApi.getOrdersByUserId,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)

export const createOrder = createAsyncThunk('orders/createorder', async (data: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.createOrder,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const updateOrder = createAsyncThunk('orders/createorder', async (payload: unknown | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.updateOrder,
    payload: payload,
    options: {
      skipLoader: false,
    },
  })
})

export const deleteOrder = createAsyncThunk('orders/deleteorder', async (id: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.deleteOrder,
    payload: id,
    options: {
      skipLoader: false,
    },
  })
})

export const getOrderById = createAsyncThunk('orders/getOrderById', async (id: string | undefined, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.getOrderById,
    payload: id,
    options: {
      skipLoader: false,
    },
  })
})

export const getFinishedOrders = createAsyncThunk('orders/finished-order', async (data: any | null, thunkAPI) => {
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: orderApi.getFinishedOrders,
    payload: data,
    options: {
      skipLoader: false,
    },
  })
})

export const unlockOrder = createAsyncThunk(
  'order/unlockOrder',
  async (data: any | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: orderApi.unlockOrder,
      payload: data,
      options: {
        skipLoader: false
      }
    })
  }
)

export const updateOrderStatus = createAsyncThunk(
  'order/updateOrderStatus',
  async (data: any | undefined, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: orderApi.updateStatus,
      payload: data,
      options: {
        skipLoader: false
      }
    })
  }
)


const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    handleChangePage: (state, action) => {
      const {activePage, page} = action.payload
      state.paginationData[activePage].page = page
    },
    handleChangePageSize: (state, action) => {
      const {activePage, pageSize} = action.payload
      state.paginationData[activePage].pageSize = pageSize
    },
    handleChangeTotalPage: (state, action) => {
      const {activePage, totalPage = 1} = action.payload
      state.paginationData[activePage].totalPage = totalPage
    },
    extraReducers: () => {},
  },
})

const {reducer, actions} = order
export const {handleChangePage, handleChangePageSize, handleChangeTotalPage} = actions
export default reducer
