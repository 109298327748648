import axiosClient from './axiosClient'
import {PayloadFactory} from './types/factory'

const factoryApi = {
  getAllFactories: (params: any) => {
    const url = '/factory/getall'
    return axiosClient.get(url, {params})
  },
  postFactory: (data: PayloadFactory) => {
    const postData = {
      name: data.name,
      country: data.country,
      contactPersons: [data.contactPerson],
      email: data.email,
      phoneNumbers: [data.phoneNumber],
      address: data.address,
      remark: data.remark,
    }
    const url = '/factory/createfactory'
    return axiosClient.post(url, postData)
  },
  updateFactory: (data: PayloadFactory) => {
    const updateData = {
      name: data.name,
      country: data.country,
      contactPersons: [data.contactPerson],
      email: data.email,
      phoneNumbers: [data.phoneNumber],
      address: data.address,
      remark: data.remark,
    }
    const id = data.id
    const url = `/factory/updatefactory/${id}`
    return axiosClient.put(url, updateData)
  },
  deleteFactory: (id: string) => {
    const url = `/factory/deletefactory?Id=${id}`
    return axiosClient.delete(url)
  },
}

export default factoryApi
