// Core
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

// MUI
import { CssBaseline, ThemeProvider } from '@mui/material'

// App
import { muiThemes } from '~/themes'
import { routesConfig } from '~/configs'
import { AppFallbackError, AppFallbackLoading, AppRouteGuard } from '~/components'
import { Provider } from 'react-redux'
import store from './app/store'

const App = () => {
  // Template

  return (
    <Provider store={store}>
        <ThemeProvider theme={muiThemes['light']}>
          <CssBaseline />
          <ErrorBoundary
            FallbackComponent={AppFallbackError}
            onError={(error, info) => {
              console.error('Error boundary caught an error:', error, info)
            }}
            onReset={() => {
              window.location.href = '/'
            }}
          >
            <Suspense fallback={<AppFallbackLoading />}>
              <Router>
                <Routes>
                  {routesConfig.map((route) => (
                    <Route key={route.path} path={route.path} element={<AppRouteGuard route={route} />} />
                  ))}
                  <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
              </Router>
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
    </Provider>
  )
}

export default App
